//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Null } from '@/components';
import { pt } from '@/hooks';
const hkPt = new pt();
export default {
  components: {
    Null,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getInit();
  },
  computed: {
    pages() {
      return this.$store.state.page.pageInfo;
    },
    configInfo() {
      return this.$store.state.base.configInfo;
    },
    q() {
      return this.$route.query.q;
    },
  },
  watch: {
    q: {
      handler(newVal) {
        this.getSearch(newVal);
      },
      immediate: true,
    },
  },
  methods: {
    async getInit() {
      document.title = this.configInfo.storeName;
    },
    async getSearch(newVal) {
      const q = newVal || this.q;
      this.list = await hkPt.getSearch(q);
      console.log('this.list---');
      console.log(this.list);
    },
  },
};
